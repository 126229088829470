$mainColor: black;
$secondaryColor: white;

$width: 1185px;

@mixin mobile {
  @media (max-width: #{$width}) {
    @content;
  }
}

@mixin for-phone-only {
  @media only screen and (min-device-width: 290px) and (max-device-width: 480px) and (orientation: portrait) {
    @content;
  }
}
@mixin for-phone-landscape {
  @media only screen and (min-device-width: 567px) and (max-width: 812px) and (orientation: landscape) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
