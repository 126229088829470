@import "../../global.scss";

.intro {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  @include for-phone-only {
    flex-direction: column;
    align-items: center;
  }

  .introMessage {
    width: 100%;
    height: 100%;
    padding-left: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include for-phone-only {
      padding-left: 0;
      align-items: center;
      height: 70%;
      overflow: hidden;
    }
    @include for-phone-landscape {
      padding-left: 20px;
      overflow: hidden;
    }

    h1 {
      font-size: 50px;
      margin: 10px 0;
      text-align: center;
      color: white;

      @include for-phone-only {
        font-size: 50px;
      }
      @include for-phone-landscape {
        font-size: 40px;
      }
    }
  }

  .icons {
    display: flex;
    align-content: center;
    justify-items: center;
    justify-content: center;
    padding: 1rem 0;
    font-size: 40px;

    @include for-phone-only {
      font-size: 22px;
    }
    @include for-phone-landscape {
      font-size: 24px;
    }

    .icon {
      padding-right: 15px;
      color: white;
    }
    .icon-gh:hover {
      color: rgba(212, 45, 212, 1);
    }
    .icon-li:hover {
      color: rgba(22, 102, 197, 1);
    }
    .icon-ig:hover {
      color: rgba(225, 48, 107, 1);
    }
    .icon-tr:hover {
      color: rgba(29, 161, 242, 1);
    }
    .icon-fb:hover {
      color: rgba(24, 119, 242, 1);
    }
    .icon-yt:hover {
      color: rgba(225, 0, 0, 1);
    }
  }
}
