@import "../../global.scss";

.navbar {
  width: 100%;
  height: 70px;
  background-color: white;
  // border-style: solid;
  border-bottom: 1px solid $mainColor;
  color: $mainColor; //set in global.scss
  position: fixed;
  top: 0;
  z-index: 3;
  transition: all 1s ease;

  .wrapper {
    padding: 10px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      .logo {
        font-size: 40px;
        font-weight: 700;
        text-decoration: none;
        color: black;
        margin-left: 1rem;

        @include mobile {
          margin-left: 0rem;
        }
        .lastname {
          color: black;
          font-size: 40px;
          font-weight: 600;
        }
        .slash {
          color: white;
        }
      }
    }
    .right {
      .hamburger {
        width: 32px;
        height: 25px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        span {
          width: 100%;
          height: 3px;
          // background-color: black;
          transform-origin: left;
          transition: all 2s ease;

          &:first-child {
            background-color: black;
          }
          &:nth-child(2) {
            background-color: black;
          }
          &:last-child {
            background-color: black;
          }
        }
      }
    }
  }
  &.active {
    background-color: $mainColor;
    color: white;
    .left {
      .logo {
        color: white;
        .lastname {
          color: white;
          font-size: 40px;
          font-weight: 600;
        }
      }
    }
    .right {
      .hamburger {
        span {
          &:first-child {
            background-color: white;
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:last-child {
            background-color: white;
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
